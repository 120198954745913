import React, {useState, useEffect, Fragment} from 'react';
import { Route, Redirect } from "react-router-dom";
import { createBiolinkUser, loginUser, validateEnterData } from '../helpers/utils';

const SignupForm = ({type, setUser}) =>{
    const [data, setData] = useState({
        email: '',
        username: '',
        password: '',
        password2: '' })

   return  (
     <div className="form">
        <input className="form__input" placeholder="Email" type="email" onChange={(e)=>{
            setData({...data, email: e.target.value.trim().toLowerCase()})
        }}/>
        <input className="form__input"  type="password"  placeholder="Password" onChange={(e)=>{
            setData({...data, password: e.target.value})
        }}/>

        {(type==='signup') && 
            <Fragment>
            <input className="form__input"  placeholder="Username" type="text" onChange={(e)=>{
                setData({...data, username: e.target.value.trim().toLowerCase()})
            }}/>
            <input className="form__input"  placeholder="Confirm Password"  type="password" onChange={(e)=>{
                setData({...data, password2: e.target.value})
            }}/>
            
            </Fragment>  
        }

        <button className="btn" onClick={()=>{
            if(!validateEnterData(data, type)){
                 alert('Please complete the fields')
                 return
                }
                console.log({data})
            if(type === 'signup'){
                createBiolinkUser({email: data.email, password: data.password, password2: data.password2, username: data.username})
                .then(({data})=>{
                    //Push this to the state
                    setUser(data)
                })
            }else if (type === 'login'){
                loginUser({email: data.email, password: data.password})
                .then(({data})=>{
                    //Push this to the state
                    setUser(data)
                })
            }
        }}>DONE</button>
    </div>
    )

}

export default  SignupForm;