import React, {useState, useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";

const BiolinkEditorItem = ({id, icon, text, url, updateLink}) =>{

    // const [linkData, setLinkData]  = useState({id, icon, text, url})
    //If user render biolink edit, else render biolink card
    //Text to the link is required + the URL on the link

    return (
    <div className="">
        <div className="biolink__group">
            <label className="biolink__group-heading">Link</label>
            <label  className="biolink__group-sub-heading">Enter URL (required)</label>
            <input onBlur={(e)=>{
                //Add protocol if it doesn't exist in the string
                let val = e.target.value
                if(!val.includes('https://')) val = `https://${val}`;
                updateLink(id, 'url', val)
            }} defaultValue={url ? url:null} className="biolink__group-input" type="url" />
            <label  className="biolink__group-sub-heading">Enter Button Text (required)</label>
            <input  onBlur={(e)=>{
                updateLink(id, 'text', e.target.value)
            }}
            defaultValue={text ? text:null}  className="biolink__group-input" type="text" />
        </div>
        {/* <button onClick={()=>{
            //Find the item with id remove that then add the object
        }} className="btn">CREATE</button> */}
    </div>
    
    )
}

export default  BiolinkEditorItem;