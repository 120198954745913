import logo from './logo.svg';
import './App.css';
import Signup from './components/Signup'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import React, {useState, useEffect, Fragment} from 'react';
import ProtectedRoute from './components/ProtectedRoute'
import Loading from './components/Loading'
import Biolink from './components/Biolink'
import {loginUser} from './helpers/utils'

function App() {
  const [user, setUser] = useState(null)
  // const [user, setUser] = useState({username:'bobby', links:[]})
    useEffect(()=>{ //NOTE - This runs once
      // if(!user){
      //   loginUser({email:null, password:null})
      //   .then(({data})=>{
      //     setUser(data)
      //     //Redirect to a biolink page
      //   })
      // }
  }, [])

  return(
 /*
    - Slash route renders loading page
    - Once we have user data then we push history edit biolink page OR it'll automaticatlly take us to signup page
    - Signup page - Either Validate login info and create a new user THEN update main app state user
    -Biolink => Edit biolink page - Show editing tools and create biolink whenever they click save (Reload the page or push the data). Should 
    - Biolink => Show biolink page - Just request data simply
    - CSS (We will use something like tailwind or just get some responsive styles barebones)
 */
        <Router>
            <Switch>
                {/* This handles the base / route */}
                <ProtectedRoute exact isAuthenticated={user} path="/"  push={true} component={Loading}/>
                <Route path="/login" render={(props)=> user ? <Redirect to="/edit/biolink" /> : <Signup  setUser={setUser} {...props}/>}/>
                <Route path="/@:username"  component={Biolink}/>
                <ProtectedRoute user={user}  isAuthenticated={user} path="/edit/biolink" component={Biolink}/>
            </Switch>
        </Router>
)
}

export default App;
