import React, {useState, useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({component: Component, user, isAuthenticated, push, ...rest}) =>{

    return (
        <Route
        {...rest}
        render={(props) =>(
          isAuthenticated ? 
           <Component user={user} pushToHistory={push}  {...props} />  : 
           <Redirect to="/login" />
        )}
      />
    )
}

export default  ProtectedRoute;