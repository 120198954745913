import React, {useState, useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";
import { getBiolink } from '../helpers/utils';

const BiolinkCard = ({}) =>{
    const [user, setUser] = useState('')
    const [noUser, setNoUser] = useState(false)
    const [links, setLinks] = useState([])
    useEffect(()=>{
        const username = (window.location.href.split('@')[1]).split('?')[0]
        setUser(username)
        // setLinks([{icon:'', text:'Reddit discount 😊', url:'reddit.com'},{icon:'', text:'Reddit discount 😊', url:'reddit.com'}, ])
        getBiolink({username})
        .then(({data, message})=>{
            if((message === 'Not Found') || (message === 'No username' )){
                setNoUser(true)
            }else{
                setLinks(data)
            }
        })
    }, [])

    return (
    <div className="">
        <h1>@{user}</h1>
        {
            links.map(({text, url})=>{
                return <a href={url} target="_blank" className="biolink__button"> {text} </a>
            })
        }
        {noUser && <h1>Nothing Available</h1>}

    </div>
    
    )
}

export default  BiolinkCard;