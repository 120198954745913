import React, {useState, useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";

const Loading = ({}) =>{

    return (
    
    <div className="loader">Loading...</div>
    
    )
}

export default  Loading;