import React, {useState, useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";
import BiolinkEditorItem from './BiolinkEditorItem'
import {createBiolink, validateLinks} from '../helpers/utils'

const BiolinkEditor = ({user}) =>{

    const [links, setLinks]  = useState(user.links)
    //If user render biolink edit, else render biolink card
    //Text to the link is required + the URL on the link
    /*
        Split it into components.
        Instead [{id, icon, text, url}, {id, icon, text, url}]
    */

    const updateLink = (id, key, value) => {
        const item =  links.find(it=>it.id===id);
        const newObj = item ? {...item, [key]: value.trim(), icon:''} : {[key]: value.trim(), id, icon:''}
        const filteredItems = links.filter(it=>it.id!= id)
        const finalLinks = [...filteredItems, newObj]
        setLinks(finalLinks)
    }

    return (
    
    <div className="">
        <p onClick={()=>{
            alert('This is a tool similar to linktree that allows you to put multiple links you have on one page. You can have up to 6 links')
        }}>What is this ?</p>
        <a style={{color: 'white'}} href={`https://bio.selleraider.com/@${user.username}`}>Click to go to your biolink</a>
        {
        ([1,2,3,4,5,6].map((current, i)=>{
            let item = links.find(it=>it.id===current)
            // if(!item) item = {};
            return <BiolinkEditorItem 
            key={item ? item.id: current} 
            id={item ? item.id: current} 
            icon ={item ? item.icon: ''}  
            text ={item ? item.text: ''} 
            url ={item ? item.url: ''} 
            updateLink={updateLink}
            />
        })
        )
        }

        <button onClick={()=>{
           if(!validateLinks(links)){
               
            alert('Please fill out the form properly');
            return
        }
            console.log({links})
            createBiolink(links)
            .then(err=>{
                //Open their biolink page in a new tab
                window.open(`https://bio.selleraider.com/@${user.username}`,'_blank');
            })
        }} className="btn">CREATE</button>
    </div>
    
    )
}

export default  BiolinkEditor;