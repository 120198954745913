const fetchApi = ({method, body, path}) =>{
    const config = {
        method,
        body: JSON.stringify(body),
        credentials: 'include',
        "headers": {
            'Content-Type': 'application/json'
        }
    }
    console.log({config})
    return fetch(`https://app.autodepop.com/api/${path}`, config)
    .then(res=>res.json())
    .then(data=>data)
    .catch(err=>{
        console.log(err)
        alert(err)
    })
}

export const loginUser = async ({email, password}) =>{
    return fetchApi({
        path: 'loginToBiolink',
        method: 'POST',
        body: {
            email, 
            password
        }
    })
}

export const createBiolinkUser = async ({email, password, password2, username}) =>{
    return fetchApi({
        path: 'createBiolinkUser',
        method: 'POST',
        body: {
            email, 
            password, 
            password2, 
            username
        }
    })
}

export const createBiolink = async (links) =>{
    return fetchApi({
        path: 'createBiolink',
        method: 'POST',
        body: {
            links
        }
    })
}

export const getBiolink = async ({username}) =>{
    return fetchApi({
        path: `biolink/@${username}`,
        method: 'GET',
    })
}

export const validateEnterData = (data, type)=>{
    if(type==='signup'){
        if(!data.email ||  !data.password||  !data.username || !data.password2) return false;
        return true;
    }else if (type === 'login'){
        if(!data.email ||  !data.password) return false;
        return true;
    }

    return false
}


export const validateLinks = (links)=>{
    console.log({toValidLinks: links});
    let isError = false
    for(const {url, text} of links){

        if(!url || !text){
            isError = true;
        }
    }

    return isError ? false:true
}

export const formatLinks = (links) =>{
    return links.map(({id, icon, text, url})=>{
        return {
            id,
            icon,
            text,
            url,
        }
    })
}