import React, {useState, useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";
import SignupForm from './SignupForm'
const Signup = ({setUser}) =>{

    const [type, setType] = useState('signup')

    return (
    
    <div className="container">
        <img src="https://selleraider.com/wp-content/uploads/2020/10/cropped-LargeSnap-74x74.png"/>
    <h1>{type === 'signup' ? 'Biolink Sign Up': 'Biolink Log In'}</h1>

    <label>Sign Up
    <input type="radio" name="type" value="signup" onChange={(e)=>{
        if(e.target.checked) setType('signup')
    }}/>
    </label>
    <label>Log In
    <input type="radio" name="type" value="login" onChange={(e)=>{
        if(e.target.checked) setType('login')
    }}/>
    </label>

    <p>please use the email attached to your depop account :)</p>

    <SignupForm setUser={setUser} type={type}/>
      
    </div>
    
    )
}

export default  Signup;