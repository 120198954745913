import React, {useState, useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";
import BiolinkEditor from './BiolinkEditor'
import BiolinkCard from './BiolinkCard'

const Biolink = ({user}) =>{
    //If user render biolink edit, else render biolink card
    //TODO - Seems like we are persisting login in app state not with cookies at all
    return (
    
    <div className="container">
        <img src="https://selleraider.com/wp-content/uploads/2020/10/cropped-LargeSnap-74x74.png"/>
        <div className="biolink">
        {user ?  <BiolinkEditor user={user}/> :  <BiolinkCard />  }
        <span className="biolink__footer">Powered by <a href="https://selleraider.com">SellerAider</a></span>
        </div>
    </div>
    
    )
}

export default  Biolink;